import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='home-container'>
    <div className='hero-container'>
      <video autoPlay muted playsInline loop>
        <source src="/videos/home-video.mov" />
      </video>

      
      <h1>Lift Bro</h1>
      <p>Fitness plans for people with no time.</p>
      <p>New parents and tech professionals, these plans are for you!</p>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline--outline'
          buttonSize='btn--large'
        >
          GET STARTED
        </Button>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={console.log('hey')}
        >
          WATCH TRAILER <i className='far fa-play-circle' />
        </Button>
      </div>
    </div>
    </div>
  );
}

export default HeroSection;