import React from 'react';
import CardItem from './CardItem';
import './Cards.css';

function Cards() {
	return (
		<div className='cards'>
			<h1>Check out these EPIC fitness plans!</h1>
			<div className='cards__container'>
				<div className='cards__wrapper'>
					<ul className='cards__items'>
						<CardItem 
							src='images/muscles.jpg'
							text='Gain muscle mass'
							label='Fitness'
							path='/services'
						/>
						<CardItem 
							src='images/general-nutrition.jpg'
							text='Dietitian-approved nutrition'
							label='Nutrition'
							path='/services'
						/>
					</ul>
					<ul className='cards__items'>
						<CardItem 
							src='images/strength.jpg'
							text='Maximize strength gain'
							label='Fitness'
							path='/services'
						/>
						<CardItem 
							src='images/weightLossFitness.jpg'
							text='Sustainable weight loss'
							label='Fitness'
							path='/products'
						/>
						<CardItem 
							src='images/weightLossNutrition.jpg'
							text='Nutrition plan for sustainable weight loss'
							label='Nutrition'
							path='/sign-up'
						/>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default Cards;