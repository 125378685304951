import React, {useState, useEffect} from 'react';
import './NavBar.css'
import { Button } from './Button';
import { Link } from 'react-router-dom';

function NavBar() {
	const [click, setClick] = useState(false);
	const [button, setButton] = useState(true);

	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => setClick(false);

	const showButton = () => {
		if (window.innerWidth <= 960) {
			setButton(false);
		}
		else {
			setButton(true);
		}
	};

	useEffect(() => {
		showButton();
	}, []);

	window.addEventListener('resize', showButton);

	return (
		<>
			<nav className='navbar'>
				<div className='navbar-container'>
					<Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
						Lift Bro <i class='fa-solid fa-hand-fist' />
					</Link>
					<div className='menu-icon' onClick={handleClick}>
						<i className={click ? 'fas fa-times' : 'fas fa-bars'} />
					</div>
					<ul className={click ? 'nav-menu active' : 'nav-menu'}>
						<li className='nav-item'>
							<Link 
								to='/about' 
								className='nav-links' 
								onClick={closeMobileMenu}
							>
								About
							</Link>
						</li>
						<li className='nav-item'>
							<Link 
								to='/services' 
								className='nav-links' 
								onClick={closeMobileMenu}
							>
								Services
							</Link>
						</li>
						<li className='nav-item'>
							<Link 
								to='/products' 
								className='nav-links' 
								onClick={closeMobileMenu}
							>
								Products
							</Link>
						</li>
						<li className='nav-item'>
							<Link 
								to='/sign-up' 
								className='nav-links-mobile' 
								onClick={closeMobileMenu}
							>
								Sign Up
							</Link>
						</li>
					</ul>
					{button && <Button buttonStyle='btn--outline'>SIGN UP</Button>}
				</div>
			</nav>
		</>
	)
}

export default NavBar;